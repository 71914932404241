exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-durgaji-durgaji-marble-idol-js": () => import("./../../../src/pages/durgaji/durgaji-marble-idol.js" /* webpackChunkName: "component---src-pages-durgaji-durgaji-marble-idol-js" */),
  "component---src-pages-durgaji-durgaji-marble-moorti-js": () => import("./../../../src/pages/durgaji/durgaji-marble-moorti.js" /* webpackChunkName: "component---src-pages-durgaji-durgaji-marble-moorti-js" */),
  "component---src-pages-durgaji-durgaji-marble-murti-js": () => import("./../../../src/pages/durgaji/durgaji-marble-murti.js" /* webpackChunkName: "component---src-pages-durgaji-durgaji-marble-murti-js" */),
  "component---src-pages-durgaji-durgaji-moorti-in-jaipur-js": () => import("./../../../src/pages/durgaji/durgaji-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-durgaji-durgaji-moorti-in-jaipur-js" */),
  "component---src-pages-durgaji-durgaji-moorti-in-rajasthan-js": () => import("./../../../src/pages/durgaji/durgaji-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-durgaji-durgaji-moorti-in-rajasthan-js" */),
  "component---src-pages-durgaji-index-js": () => import("./../../../src/pages/durgaji/index.js" /* webpackChunkName: "component---src-pages-durgaji-index-js" */),
  "component---src-pages-durgaji-marble-durgaji-idol-js": () => import("./../../../src/pages/durgaji/marble-durgaji-idol.js" /* webpackChunkName: "component---src-pages-durgaji-marble-durgaji-idol-js" */),
  "component---src-pages-durgaji-marble-durgaji-moorti-js": () => import("./../../../src/pages/durgaji/marble-durgaji-moorti.js" /* webpackChunkName: "component---src-pages-durgaji-marble-durgaji-moorti-js" */),
  "component---src-pages-durgaji-marble-durgaji-murti-js": () => import("./../../../src/pages/durgaji/marble-durgaji-murti.js" /* webpackChunkName: "component---src-pages-durgaji-marble-durgaji-murti-js" */),
  "component---src-pages-durgaji-marble-durgaji-statue-js": () => import("./../../../src/pages/durgaji/marble-durgaji-statue.js" /* webpackChunkName: "component---src-pages-durgaji-marble-durgaji-statue-js" */),
  "component---src-pages-ganesha-ganesh-marble-moorti-js": () => import("./../../../src/pages/ganesha/ganesh-marble-moorti.js" /* webpackChunkName: "component---src-pages-ganesha-ganesh-marble-moorti-js" */),
  "component---src-pages-ganesha-ganesh-marble-murti-js": () => import("./../../../src/pages/ganesha/ganesh-marble-murti.js" /* webpackChunkName: "component---src-pages-ganesha-ganesh-marble-murti-js" */),
  "component---src-pages-ganesha-ganesh-moorti-in-jaipur-js": () => import("./../../../src/pages/ganesha/ganesh-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-ganesha-ganesh-moorti-in-jaipur-js" */),
  "component---src-pages-ganesha-ganesh-moorti-in-rajasthan-js": () => import("./../../../src/pages/ganesha/ganesh-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-ganesha-ganesh-moorti-in-rajasthan-js" */),
  "component---src-pages-ganesha-ganpati-marble-idol-js": () => import("./../../../src/pages/ganesha/ganpati-marble-idol.js" /* webpackChunkName: "component---src-pages-ganesha-ganpati-marble-idol-js" */),
  "component---src-pages-ganesha-index-js": () => import("./../../../src/pages/ganesha/index.js" /* webpackChunkName: "component---src-pages-ganesha-index-js" */),
  "component---src-pages-ganesha-marble-ganesh-moorti-js": () => import("./../../../src/pages/ganesha/marble-ganesh-moorti.js" /* webpackChunkName: "component---src-pages-ganesha-marble-ganesh-moorti-js" */),
  "component---src-pages-ganesha-marble-ganesh-murti-js": () => import("./../../../src/pages/ganesha/marble-ganesh-murti.js" /* webpackChunkName: "component---src-pages-ganesha-marble-ganesh-murti-js" */),
  "component---src-pages-ganesha-marble-ganesh-statue-js": () => import("./../../../src/pages/ganesha/marble-ganesh-statue.js" /* webpackChunkName: "component---src-pages-ganesha-marble-ganesh-statue-js" */),
  "component---src-pages-ganesha-marble-ganpati-idol-js": () => import("./../../../src/pages/ganesha/marble-ganpati-idol.js" /* webpackChunkName: "component---src-pages-ganesha-marble-ganpati-idol-js" */),
  "component---src-pages-hanuman-hanuman-marble-idol-js": () => import("./../../../src/pages/hanuman/hanuman-marble-idol.js" /* webpackChunkName: "component---src-pages-hanuman-hanuman-marble-idol-js" */),
  "component---src-pages-hanuman-hanuman-marble-moorti-js": () => import("./../../../src/pages/hanuman/hanuman-marble-moorti.js" /* webpackChunkName: "component---src-pages-hanuman-hanuman-marble-moorti-js" */),
  "component---src-pages-hanuman-hanuman-marble-murti-js": () => import("./../../../src/pages/hanuman/hanuman-marble-murti.js" /* webpackChunkName: "component---src-pages-hanuman-hanuman-marble-murti-js" */),
  "component---src-pages-hanuman-hanuman-moorti-in-jaipur-js": () => import("./../../../src/pages/hanuman/hanuman-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-hanuman-hanuman-moorti-in-jaipur-js" */),
  "component---src-pages-hanuman-hanuman-moorti-in-rajasthan-js": () => import("./../../../src/pages/hanuman/hanuman-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-hanuman-hanuman-moorti-in-rajasthan-js" */),
  "component---src-pages-hanuman-index-js": () => import("./../../../src/pages/hanuman/index.js" /* webpackChunkName: "component---src-pages-hanuman-index-js" */),
  "component---src-pages-hanuman-marble-hanuman-idol-js": () => import("./../../../src/pages/hanuman/marble-hanuman-idol.js" /* webpackChunkName: "component---src-pages-hanuman-marble-hanuman-idol-js" */),
  "component---src-pages-hanuman-marble-hanuman-moorti-js": () => import("./../../../src/pages/hanuman/marble-hanuman-moorti.js" /* webpackChunkName: "component---src-pages-hanuman-marble-hanuman-moorti-js" */),
  "component---src-pages-hanuman-marble-hanuman-murti-js": () => import("./../../../src/pages/hanuman/marble-hanuman-murti.js" /* webpackChunkName: "component---src-pages-hanuman-marble-hanuman-murti-js" */),
  "component---src-pages-hanuman-marble-hanuman-statue-js": () => import("./../../../src/pages/hanuman/marble-hanuman-statue.js" /* webpackChunkName: "component---src-pages-hanuman-marble-hanuman-statue-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-krishna-index-js": () => import("./../../../src/pages/krishna/index.js" /* webpackChunkName: "component---src-pages-krishna-index-js" */),
  "component---src-pages-krishna-krishna-marble-idol-js": () => import("./../../../src/pages/krishna/krishna-marble-idol.js" /* webpackChunkName: "component---src-pages-krishna-krishna-marble-idol-js" */),
  "component---src-pages-krishna-krishna-marble-moorti-js": () => import("./../../../src/pages/krishna/krishna-marble-moorti.js" /* webpackChunkName: "component---src-pages-krishna-krishna-marble-moorti-js" */),
  "component---src-pages-krishna-krishna-marble-murti-js": () => import("./../../../src/pages/krishna/krishna-marble-murti.js" /* webpackChunkName: "component---src-pages-krishna-krishna-marble-murti-js" */),
  "component---src-pages-krishna-krishna-moorti-in-jaipur-js": () => import("./../../../src/pages/krishna/krishna-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-krishna-krishna-moorti-in-jaipur-js" */),
  "component---src-pages-krishna-krishna-moorti-in-rajasthan-js": () => import("./../../../src/pages/krishna/krishna-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-krishna-krishna-moorti-in-rajasthan-js" */),
  "component---src-pages-krishna-marble-krishna-idol-js": () => import("./../../../src/pages/krishna/marble-krishna-idol.js" /* webpackChunkName: "component---src-pages-krishna-marble-krishna-idol-js" */),
  "component---src-pages-krishna-marble-krishna-moorti-js": () => import("./../../../src/pages/krishna/marble-krishna-moorti.js" /* webpackChunkName: "component---src-pages-krishna-marble-krishna-moorti-js" */),
  "component---src-pages-krishna-marble-krishna-murti-js": () => import("./../../../src/pages/krishna/marble-krishna-murti.js" /* webpackChunkName: "component---src-pages-krishna-marble-krishna-murti-js" */),
  "component---src-pages-krishna-marble-krishna-statue-js": () => import("./../../../src/pages/krishna/marble-krishna-statue.js" /* webpackChunkName: "component---src-pages-krishna-marble-krishna-statue-js" */),
  "component---src-pages-radhakrishna-index-js": () => import("./../../../src/pages/radhakrishna/index.js" /* webpackChunkName: "component---src-pages-radhakrishna-index-js" */),
  "component---src-pages-radhakrishna-marble-radha-krishna-idol-js": () => import("./../../../src/pages/radhakrishna/marble-radha-krishna-idol.js" /* webpackChunkName: "component---src-pages-radhakrishna-marble-radha-krishna-idol-js" */),
  "component---src-pages-radhakrishna-marble-radha-krishna-moorti-js": () => import("./../../../src/pages/radhakrishna/marble-radha-krishna-moorti.js" /* webpackChunkName: "component---src-pages-radhakrishna-marble-radha-krishna-moorti-js" */),
  "component---src-pages-radhakrishna-marble-radha-krishna-murti-js": () => import("./../../../src/pages/radhakrishna/marble-radha-krishna-murti.js" /* webpackChunkName: "component---src-pages-radhakrishna-marble-radha-krishna-murti-js" */),
  "component---src-pages-radhakrishna-marble-radha-krishna-statue-js": () => import("./../../../src/pages/radhakrishna/marble-radha-krishna-statue.js" /* webpackChunkName: "component---src-pages-radhakrishna-marble-radha-krishna-statue-js" */),
  "component---src-pages-radhakrishna-radha-krishna-marble-idol-js": () => import("./../../../src/pages/radhakrishna/radha-krishna-marble-idol.js" /* webpackChunkName: "component---src-pages-radhakrishna-radha-krishna-marble-idol-js" */),
  "component---src-pages-radhakrishna-radha-krishna-marble-moorti-js": () => import("./../../../src/pages/radhakrishna/radha-krishna-marble-moorti.js" /* webpackChunkName: "component---src-pages-radhakrishna-radha-krishna-marble-moorti-js" */),
  "component---src-pages-radhakrishna-radha-krishna-marble-murti-js": () => import("./../../../src/pages/radhakrishna/radha-krishna-marble-murti.js" /* webpackChunkName: "component---src-pages-radhakrishna-radha-krishna-marble-murti-js" */),
  "component---src-pages-radhakrishna-radha-krishna-moorti-in-jaipur-js": () => import("./../../../src/pages/radhakrishna/radha-krishna-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-radhakrishna-radha-krishna-moorti-in-jaipur-js" */),
  "component---src-pages-radhakrishna-radha-krishna-moorti-in-rajasthan-js": () => import("./../../../src/pages/radhakrishna/radha-krishna-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-radhakrishna-radha-krishna-moorti-in-rajasthan-js" */),
  "component---src-pages-ramdarbar-index-js": () => import("./../../../src/pages/ramdarbar/index.js" /* webpackChunkName: "component---src-pages-ramdarbar-index-js" */),
  "component---src-pages-ramdarbar-marble-ramdarbar-idol-js": () => import("./../../../src/pages/ramdarbar/marble-ramdarbar-idol.js" /* webpackChunkName: "component---src-pages-ramdarbar-marble-ramdarbar-idol-js" */),
  "component---src-pages-ramdarbar-marble-ramdarbar-moorti-js": () => import("./../../../src/pages/ramdarbar/marble-ramdarbar-moorti.js" /* webpackChunkName: "component---src-pages-ramdarbar-marble-ramdarbar-moorti-js" */),
  "component---src-pages-ramdarbar-marble-ramdarbar-murti-js": () => import("./../../../src/pages/ramdarbar/marble-ramdarbar-murti.js" /* webpackChunkName: "component---src-pages-ramdarbar-marble-ramdarbar-murti-js" */),
  "component---src-pages-ramdarbar-marble-ramdarbar-statue-js": () => import("./../../../src/pages/ramdarbar/marble-ramdarbar-statue.js" /* webpackChunkName: "component---src-pages-ramdarbar-marble-ramdarbar-statue-js" */),
  "component---src-pages-ramdarbar-ramdarbar-marble-idol-js": () => import("./../../../src/pages/ramdarbar/ramdarbar-marble-idol.js" /* webpackChunkName: "component---src-pages-ramdarbar-ramdarbar-marble-idol-js" */),
  "component---src-pages-ramdarbar-ramdarbar-marble-moorti-js": () => import("./../../../src/pages/ramdarbar/ramdarbar-marble-moorti.js" /* webpackChunkName: "component---src-pages-ramdarbar-ramdarbar-marble-moorti-js" */),
  "component---src-pages-ramdarbar-ramdarbar-marble-murti-js": () => import("./../../../src/pages/ramdarbar/ramdarbar-marble-murti.js" /* webpackChunkName: "component---src-pages-ramdarbar-ramdarbar-marble-murti-js" */),
  "component---src-pages-ramdarbar-ramdarbar-moorti-in-jaipur-js": () => import("./../../../src/pages/ramdarbar/ramdarbar-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-ramdarbar-ramdarbar-moorti-in-jaipur-js" */),
  "component---src-pages-ramdarbar-ramdarbar-moorti-in-rajasthan-js": () => import("./../../../src/pages/ramdarbar/ramdarbar-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-ramdarbar-ramdarbar-moorti-in-rajasthan-js" */),
  "component---src-pages-ramdevji-index-js": () => import("./../../../src/pages/ramdevji/index.js" /* webpackChunkName: "component---src-pages-ramdevji-index-js" */),
  "component---src-pages-ramdevji-marble-ramdevji-idol-js": () => import("./../../../src/pages/ramdevji/marble-ramdevji-idol.js" /* webpackChunkName: "component---src-pages-ramdevji-marble-ramdevji-idol-js" */),
  "component---src-pages-ramdevji-marble-ramdevji-moorti-js": () => import("./../../../src/pages/ramdevji/marble-ramdevji-moorti.js" /* webpackChunkName: "component---src-pages-ramdevji-marble-ramdevji-moorti-js" */),
  "component---src-pages-ramdevji-marble-ramdevji-murti-js": () => import("./../../../src/pages/ramdevji/marble-ramdevji-murti.js" /* webpackChunkName: "component---src-pages-ramdevji-marble-ramdevji-murti-js" */),
  "component---src-pages-ramdevji-marble-ramdevji-statue-js": () => import("./../../../src/pages/ramdevji/marble-ramdevji-statue.js" /* webpackChunkName: "component---src-pages-ramdevji-marble-ramdevji-statue-js" */),
  "component---src-pages-ramdevji-ramdevji-marble-idol-js": () => import("./../../../src/pages/ramdevji/ramdevji-marble-idol.js" /* webpackChunkName: "component---src-pages-ramdevji-ramdevji-marble-idol-js" */),
  "component---src-pages-ramdevji-ramdevji-marble-moorti-js": () => import("./../../../src/pages/ramdevji/ramdevji-marble-moorti.js" /* webpackChunkName: "component---src-pages-ramdevji-ramdevji-marble-moorti-js" */),
  "component---src-pages-ramdevji-ramdevji-marble-murti-js": () => import("./../../../src/pages/ramdevji/ramdevji-marble-murti.js" /* webpackChunkName: "component---src-pages-ramdevji-ramdevji-marble-murti-js" */),
  "component---src-pages-ramdevji-ramdevji-moorti-in-jaipur-js": () => import("./../../../src/pages/ramdevji/ramdevji-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-ramdevji-ramdevji-moorti-in-jaipur-js" */),
  "component---src-pages-ramdevji-ramdevji-moorti-in-rajasthan-js": () => import("./../../../src/pages/ramdevji/ramdevji-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-ramdevji-ramdevji-moorti-in-rajasthan-js" */),
  "component---src-pages-saibaba-index-js": () => import("./../../../src/pages/saibaba/index.js" /* webpackChunkName: "component---src-pages-saibaba-index-js" */),
  "component---src-pages-saibaba-marble-saibaba-idol-js": () => import("./../../../src/pages/saibaba/marble-saibaba-idol.js" /* webpackChunkName: "component---src-pages-saibaba-marble-saibaba-idol-js" */),
  "component---src-pages-saibaba-marble-saibaba-moorti-js": () => import("./../../../src/pages/saibaba/marble-saibaba-moorti.js" /* webpackChunkName: "component---src-pages-saibaba-marble-saibaba-moorti-js" */),
  "component---src-pages-saibaba-marble-saibaba-murti-js": () => import("./../../../src/pages/saibaba/marble-saibaba-murti.js" /* webpackChunkName: "component---src-pages-saibaba-marble-saibaba-murti-js" */),
  "component---src-pages-saibaba-marble-saibaba-statue-js": () => import("./../../../src/pages/saibaba/marble-saibaba-statue.js" /* webpackChunkName: "component---src-pages-saibaba-marble-saibaba-statue-js" */),
  "component---src-pages-saibaba-saibaba-marble-idol-js": () => import("./../../../src/pages/saibaba/saibaba-marble-idol.js" /* webpackChunkName: "component---src-pages-saibaba-saibaba-marble-idol-js" */),
  "component---src-pages-saibaba-saibaba-marble-moorti-js": () => import("./../../../src/pages/saibaba/saibaba-marble-moorti.js" /* webpackChunkName: "component---src-pages-saibaba-saibaba-marble-moorti-js" */),
  "component---src-pages-saibaba-saibaba-marble-murti-js": () => import("./../../../src/pages/saibaba/saibaba-marble-murti.js" /* webpackChunkName: "component---src-pages-saibaba-saibaba-marble-murti-js" */),
  "component---src-pages-saibaba-saibaba-moorti-in-jaipur-js": () => import("./../../../src/pages/saibaba/saibaba-moorti-in-jaipur.js" /* webpackChunkName: "component---src-pages-saibaba-saibaba-moorti-in-jaipur-js" */),
  "component---src-pages-saibaba-saibaba-moorti-in-rajasthan-js": () => import("./../../../src/pages/saibaba/saibaba-moorti-in-rajasthan.js" /* webpackChunkName: "component---src-pages-saibaba-saibaba-moorti-in-rajasthan-js" */)
}

